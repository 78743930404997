<template>
  <div>
    <div v-if="isBusy || loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('PROFILES')})  | capitalize}}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('PROFILES') | capitalize">
      <b-card-text>
        <b-button class="mb-2" variant="info" @click="showModal">
          {{ $t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('PROFILES')})  | capitalize}}
        </b-button>
        <b-list-group>
          <b-list-group-item variant="dark" :key="profile.id" v-for="profile of current">{{ profile.name  | capitalize}}
          </b-list-group-item>
        </b-list-group>
        <b-modal :title="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('PROFILES')})  | capitalize" id="modal" size="xl" @ok="handleInput">
          <profile-list :only-active="true" :currently-selected="currentHrefs" @change="storeSelected"></profile-list>
        </b-modal>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {LoadData, SendsMessages, VModel} from "@/mixins";
import ProfileList from "@/users/profiles/ProfileList";

export default {
  name: "ProfileSelector",
  components: {ProfileList},
  props: ['loading', 'value', 'initialValues'],
  mixins: [LoadData, VModel, SendsMessages],
  mounted() {
    this.currentHrefs = this.value;
    this.current = this.initialValues;
  },
  watch: {
    value(val) {
      this.currentHrefs = val;
    },
    initialValues(val) {
      this.current = val;
    }
  },
  methods: {
    storeSelected(selected) {
      this.selected = selected;
      this.selectedHrefs = selected.map(x => x._links.self.href);
    },
    async showModal() {
      this.$bvModal.show('modal');
    },
    handleInput() {
      this.$emit('input', this.selectedHrefs);
      this.current = this.selected;
      this.currentHrefs = this.current.map(x => x._links.self.href)
      this.selected = [];
      this.selectedHrefs = [];
    }
  },
  data() {
    return {
      search: '',
      profiles: [],
      content: this.value,
      selected: [],
      selectedHrefs: [],
      current: [],
      currentHrefs: []
    }
  },
}
</script>

<style scoped>

</style>
